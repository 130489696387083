import {
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Stack,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Edit, Close, Delete } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";

import API from "../../lib/api";
import PageWrapper from "../../components/PageWrapper";
import SearchableTable from "../../components/SearchableTable";
import List from "../../components/List";
import moment from "moment";
import DataGridCard from "../../components/DataGridCard";

import { BLUETOOTH_DEVICE_TYPES } from "../../constants";

const TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

const BluetoothDeviceDetails = () => {
  const search_params = useParams();
  const [imei, set_imei] = useState();
  const [btmac, set_btmac] = useState();
  const [device, set_device] = useState();

  const [loading, set_loading] = useState(true);
  const [notification, set_notification] = useState({ open: false });

  const [open_delete_bluetooth_device, set_open_delete_bluetooth_device] =
    useState(false);

  const [selected_company, set_selected_company] = useState(null);
  const [search_companies, set_search_companies] = useState([]);
  const [search_companies_loading, set_search_companies_loading] =
    useState(false);

  const [selected_device_type, set_selected_device_type] = useState(null);
  const [search_device_types, set_search_device_types] = useState([]);

  const [gps_data_date_range, set_gps_data_date_range] = useState([
    moment().subtract(24, "hours").format(TIME_FORMAT),
    moment().format(TIME_FORMAT),
  ]);
  const [gps_data, set_gps_data] = useState([]);
  const gps_data_columns = [
    {
      field: "imei",
      headerName: "IMEI",
      width: 120,
    },
    {
      field: "time",
      headerName: "Time",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        moment.utc(value).local().format("HH:mm:ss - DD/MM/YYYY"),
      width: 120,
    },
    {
      field: "gpsdata",
      headerName: "Coordinates",
      width: 120,
    },
    {
      field: "alarm",
      headerName: "Alarm",
      width: 120,
    },
    {
      field: "altitude",
      headerName: "Altitude",
      width: 120,
    },
    {
      field: "batt_percent",
      headerName: "Internal Battery Percent",
      width: 120,
    },
    {
      field: "battery_volts",
      headerName: "Internal Battery Voltage",
      width: 120,
    },
    {
      field: "car_volts",
      headerName: "External Voltage",
      width: 120,
    },
    {
      field: "message_type",
      headerName: "Message Type",
      width: 120,
    },
    {
      field: "heading",
      headerName: "Heading",
      width: 120,
    },
    {
      field: "monitor_allowed",
      headerName: "Monitor Allowed",
      width: 120,
    },
    {
      field: "sat_count",
      headerName: "Satellite Count",
      width: 120,
    },
    {
      field: "odometer",
      headerName: "Odometer (M)",
      width: 120,
    },
    {
      field: "signal_percent",
      headerName: "Network %",
      width: 120,
    },
    {
      field: "speed",
      headerName: "Speed",
      width: 120,
    },
  ];

  /*
  
  
  currenttime
  
  
  
  obdjson
  
  
  
    */

  const [edit_name_state, set_edit_name_state] = useState({
    value: "",
    active: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    let btmac = search_params.btmac;
    set_btmac(btmac);

    API.bt_devices(btmac)
      .get()
      .then((device) => {
        set_device(device);

        let device_type = BLUETOOTH_DEVICE_TYPES.find(
          (device_type) => device_type.id === device.device_type
        ) || {
          id: device.device_type,
          name: "Unknown Device",
        };
        set_selected_device_type({
          id: device_type.id,
          label: device_type.name,
        });

        let requests = [];

        if (device.company_id)
          requests.push(
            API.companies(device.company_id)
              .get()
              .then((company) =>
                set_selected_company({ label: company.name, id: company.id })
              )
          );
        /*
        let [start_date, end_date] = gps_data_date_range;
        start_date = moment(start_date).utc().format(TIME_FORMAT);
        end_date = moment(end_date).utc().format(TIME_FORMAT);

        requests.push(
          API.devices(imei)
            .data()
            .get_all({ start_date, end_date, limit: 50, offset: 0 })
            .then((data) => {
              console.log(data);
              set_gps_data([...data]);
            })
        );
        */

        return Promise.all(requests);
      })
      .catch((e) => {
        console.error(e);
        set_notification({
          open: true,
          severity: "error",
          message: "Unable to load device",
        });
        navigate("/btdevices");
      })
      .finally(() => set_loading(false));
  }, []);

  function update_device({ name, device_type, imei, btmac, company_id }) {
    set_loading(true);

    API.bt_devices(btmac)
      .update({ name, company_id, device_type, btmac, imei })
      .then((device) => {
        set_device(device);
        set_loading(false);
      })
      .catch((e) => {
        console.error(e);
        set_notification({
          open: true,
          severity: "error",
          message: "Unable to update device",
          onClose: () => set_notification({ ...notification, open: false }),
        });
      });
  }

  return (
    <PageWrapper
      loading={loading}
      notification={notification}
      page_title={device !== undefined ? device.name : "Device Details"}
    >
      {device && (
        <>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              {edit_name_state.active ? (
                <TextField
                  autoFocus
                  variant="standard"
                  value={edit_name_state.value}
                  onChange={(e) =>
                    set_edit_name_state({
                      ...edit_name_state,
                      value: e.target.value,
                    })
                  }
                  fullWidth
                />
              ) : (
                <Typography color="textPrimary" variant="h5">
                  {device.name}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Stack spacing={2} direction="row">
                {edit_name_state.active && (
                  <Box sx={{ m: -1 }}>
                    <Button
                      color="primary"
                      startIcon={<Delete />}
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={() => set_open_delete_bluetooth_device(true)}
                    >
                      Delete Bluetooth Device
                    </Button>
                  </Box>
                )}
                {edit_name_state.active ? (
                  <IconButton
                    onClick={() => {
                      set_edit_name_state({
                        ...edit_name_state,
                        active: false,
                      });
                      update_device({ ...device, name: edit_name_state.value });
                    }}
                  >
                    <Close />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      set_edit_name_state({ value: device.name, active: true })
                    }
                  >
                    <Edit />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>

          <Dialog
            open={open_delete_bluetooth_device}
            onClose={() => set_open_delete_bluetooth_device(false)}
          >
            <DialogTitle>Confirm Delete Bluetooth Device</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you would like to delete this Bluetooth Device?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => set_open_delete_bluetooth_device(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  API.bt_devices(btmac)
                    .delete()
                    .then(({ success }) => {
                      if (success) {
                        navigate("/btdevices");
                      }
                    });
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Divider />
          <Grid container sx={{ mt: 3 }} spacing={3}>
            <Grid item xs={6}>
              <Card
                title="Bluetooth Device Details"
                data={[
                  {
                    name: "IMEI",
                    value: device.imei,
                    type: "string",
                  },
                  {
                    name: "BTMAC",
                    value: device.btmac,
                    type: "string",
                  },
                  {
                    name: "Type",
                    value: selected_device_type,
                    type: "select",
                    options: search_device_types,
                    onSearch: (term) => {
                      let device_types = BLUETOOTH_DEVICE_TYPES;

                      if (term !== "")
                        device_types = device_types.filter(
                          (device) =>
                            device.name
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        );

                      device_types = device_types.map((device) => ({
                        id: device.id,
                        label: device.name,
                      }));

                      set_search_device_types(device_types);
                    },
                  },
                ]}
                onChange={(values) => {
                  set_selected_device_type(values["Type"]);
                  set_imei(values["IMEI"]);
                  set_btmac(values["BTMAC"]);

                  update_device({
                    ...device,
                    mode_type: values["Type"].id,
                    imei: values["IMEI"],
                    btmac: values["BTMAC"],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Card
                title="Company Details"
                data={[
                  {
                    name: "Company",
                    value: selected_company,
                    type: "select",
                    options: search_companies,
                    loading: search_companies_loading,
                    onSearch: (term) => {
                      set_search_companies_loading(true);
                      API.companies()
                        .search({
                          offset: 0,
                          limit: 10,
                          name: term,
                        })
                        .then(({ content }) =>
                          set_search_companies(
                            content.map((company) => ({
                              id: company.id,
                              label: company.name,
                            }))
                          )
                        )
                        .finally(() => set_search_companies_loading(false));
                    },
                  },
                ]}
                onChange={(values) => {
                  set_selected_company(values["Company"]);

                  update_device({
                    ...device,
                    company_id:
                      values["Company"] === null ? null : values["Company"].id,
                  });
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};

export default BluetoothDeviceDetails;
