import { experimentalStyled } from "@mui/material/styles";

const Logo = ({ color = "white", ...props }) => (
  <>
    {color == "white" && (
      <img style={{ width: 250 }} src={"/static/logo-white.png"} alt="logo" />
    )}
    {color == "black" && (
      <img style={{ width: 250 }} src={"/static/logo.png"} alt="logo" />
    )}
  </>
);

export default Logo;
