import EditableString from "./EditableString";

const EditableNumber = (props) => {
    return <EditableString
        edit_mode={props.edit_mode}
        value={props.value === undefined ? "" : props.value}
        onChange={string_value => {
            let new_value;

            if (string_value === "") new_value = null;
            else {
                string_value = Number(string_value);
                if (!isNaN(string_value)) new_value = string_value;
            }
            
            if (new_value !== undefined) props.onChange(new_value);
        }}
        disabled={props.disabled}
    />
}

export default EditableNumber;