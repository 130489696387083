import { Suspense, lazy } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import LoadingScreen from "./components/LoadingScreen";
import BluetoothDeviceList from "./pages/dashboard/BluetoothDevicesList";
import BluetoothDeviceDetails from "./pages/dashboard/BluetoothDeviceDetails";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

// Dashboard pages

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Analytics = Loadable(lazy(() => import("./pages/dashboard/Analytics")));

const CompanyList = Loadable(
  lazy(() => import("./pages/dashboard/CompanyList"))
);
const CompanyDetails = Loadable(
  lazy(() => import("./pages/dashboard/CompanyDetails"))
);

const UserList = Loadable(lazy(() => import("./pages/dashboard/UserList")));
const UserDetails = Loadable(
  lazy(() => import("./pages/dashboard/UserDetails"))
);

const DeviceList = Loadable(lazy(() => import("./pages/dashboard/DeviceList")));
const DeviceDetails = Loadable(
  lazy(() => import("./pages/dashboard/DeviceDetails"))
);

const Finance = Loadable(lazy(() => import("./pages/dashboard/Finance")));
const InvoiceDetails = Loadable(
  lazy(() => import("./pages/dashboard/InvoiceDetails"))
);
const InvoiceList = Loadable(
  lazy(() => import("./pages/dashboard/InvoiceList"))
);
const OrderDetails = Loadable(
  lazy(() => import("./pages/dashboard/OrderDetails"))
);
const OrderList = Loadable(lazy(() => import("./pages/dashboard/OrderList")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const ProductCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProductCreate"))
);
const ProductList = Loadable(
  lazy(() => import("./pages/dashboard/ProductList"))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Projects pages

const ProjectBrowse = Loadable(
  lazy(() => import("./pages/dashboard/ProjectBrowse"))
);
const ProjectCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProjectCreate"))
);
const ProjectDetails = Loadable(
  lazy(() => import("./pages/dashboard/ProjectDetails"))
);

// Social pages

const SocialFeed = Loadable(lazy(() => import("./pages/dashboard/SocialFeed")));
const SocialProfile = Loadable(
  lazy(() => import("./pages/dashboard/SocialProfile"))
);

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "401",
    element: <AuthorizationRequired />,
  },
  {
    path: "404",
    element: <NotFound />,
  },
  {
    path: "500",
    element: <ServerError />,
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "companies",
        children: [
          {
            path: "",
            element: <CompanyList />,
          },
          {
            path: ":company_id",
            element: <CompanyDetails />,
          },
        ],
      },
      {
        path: "btdevices",
        children: [
          {
            path: "",
            element: <BluetoothDeviceList />,
          },
          {
            path: ":btmac",
            element: <BluetoothDeviceDetails />,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            path: "",
            element: <UserList />,
          },
          {
            path: ":user_id",
            element: <UserDetails />,
          },
        ],
      },
      {
        path: "devices",
        children: [
          {
            path: "",
            element: <DeviceList />,
          },
          {
            path: ":imei",
            element: <DeviceDetails />,
          },
        ],
      },
      {
        path: "invoices",
        children: [
          {
            path: "",
            element: <InvoiceList />,
          },
          {
            path: ":invoiceId",
            element: <InvoiceDetails />,
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            path: "",
            element: <OrderList />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "finance",
        element: <Finance />,
      },
      {
        path: "products",
        children: [
          {
            path: "",
            element: <ProductList />,
          },
          {
            path: "new",
            element: <ProductCreate />,
          },
        ],
      },
      {
        path: "projects",
        children: [
          {
            path: "browse",
            element: <ProjectBrowse />,
          },
          {
            path: "new",
            element: <ProjectCreate />,
          },
          {
            path: ":projectId",
            element: <ProjectDetails />,
          },
        ],
      },
      {
        path: "social",
        children: [
          {
            path: "feed",
            element: <SocialFeed />,
          },
          {
            path: "profile",
            element: <SocialProfile />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
