import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TableCell,
  TableRow,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ArrowRight } from "@mui/icons-material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import API from "../../lib/api";
import PageWrapper from "../../components/PageWrapper";
import SearchableTable from "../../components/SearchableTable";

import Editable from "../../components/Editable";
import { BLUETOOTH_DEVICE_TYPES } from "../../constants";

const BluetoothDeviceList = () => {
  const [devices, set_devices] = useState([]);
  const [total_devices, set_total_devices] = useState(0);

  const [create_device_state, set_create_device_state] = useState({
    open: false,
    loading: false,
    error: false,
    name: "",
    btmac: "",
    device_type: null,
    imei: "",
  });

  const [loading, set_loading] = useState(false);
  const [notification, set_notification] = useState({ open: false });

  const [device_search_types, set_device_search_types] = useState(
    BLUETOOTH_DEVICE_TYPES.map((device) => ({
      id: device.id,
      label: device.name,
    }))
  );

  const navigate = useNavigate();

  return (
    <PageWrapper
      loading={loading}
      notification={notification}
      page_title="Device List"
    >
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography color="textPrimary" variant="h5">
            Bluetooth Device List
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ m: -1 }}>
            <Button
              color="primary"
              startIcon={<Add />}
              sx={{ m: 1 }}
              variant="contained"
              onClick={() =>
                set_create_device_state({
                  name: "",
                  loading: false,
                  open: true,
                  error: false,
                })
              }
            >
              Add Device
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={create_device_state.open}
        onClose={() =>
          !create_device_state.loading &&
          set_create_device_state({ ...create_device_state, open: false })
        }
      >
        <DialogTitle>Add Bluetooth Device</DialogTitle>
        <DialogContent>
          {create_device_state.loading && <LinearProgress />}
          <DialogContentText>
            To add a new bluetooth device, enter the name, IMEI and MAC address
            for the device below. This can be changed later.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Device Name"
            fullWidth
            variant="standard"
            error={create_device_state.error}
            helperText={
              create_device_state.error &&
              "There was a problem creating the device"
            }
            disabled={create_device_state.loading}
            value={create_device_state.name}
            onChange={(e) =>
              set_create_device_state({
                ...create_device_state,
                name: e.target.value,
              })
            }
          />
          <TextField
            autoFocus
            margin="dense"
            label="IMEI"
            fullWidth
            variant="standard"
            error={create_device_state.error}
            helperText={
              create_device_state.error &&
              "There was a problem creating the device"
            }
            disabled={create_device_state.loading}
            value={create_device_state.imei}
            onChange={(e) =>
              set_create_device_state({
                ...create_device_state,
                imei: e.target.value,
              })
            }
          />
          <TextField
            autoFocus
            margin="dense"
            label="MAC Address"
            fullWidth
            variant="standard"
            error={create_device_state.error}
            helperText={
              create_device_state.error &&
              "There was a problem creating the device"
            }
            disabled={create_device_state.loading}
            value={create_device_state.btmac}
            onChange={(e) =>
              set_create_device_state({
                ...create_device_state,
                btmac: e.target.value,
              })
            }
          />
          <Stack sx={{ mt: 2 }} spacing={1}>
            <Typography variant="body1">Device Type</Typography>
            <Editable
              type="select"
              value={create_device_state.device_type}
              options={device_search_types}
              loading={false}
              edit_mode={true}
              disabled={false}
              onChange={(value) => {
                console.log(value);
                set_create_device_state({
                  ...create_device_state,
                  device_type: value,
                });
              }}
              onSearch={(term) => {
                if (term.length >= 1) {
                  let device_types = BLUETOOTH_DEVICE_TYPES;

                  if (term !== "")
                    device_types = device_types.filter(
                      (device) =>
                        device.name.toLowerCase().indexOf(term.toLowerCase()) >
                        -1
                    );

                  device_types = device_types.map((device) => ({
                    id: device.id,
                    label: device.name,
                  }));

                  set_device_search_types(device_types);
                } else {
                  let device_types = BLUETOOTH_DEVICE_TYPES;
                  device_types = device_types.map((device) => ({
                    id: device.id,
                    label: device.name,
                  }));
                  set_device_search_types(device_types);
                }
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              set_create_device_state({
                name: "",
                btmac: "",
                imei: "",
                open: false,
                loading: false,
                error: false,
              })
            }
            disabled={create_device_state.loading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              set_create_device_state({
                ...create_device_state,
                loading: true,
                error: false,
              });

              API.bt_devices()
                .create({
                  name: create_device_state.name,
                  imei: create_device_state.imei,
                  btmac: create_device_state.btmac,
                  device_type: create_device_state.device_type?.id,
                })
                .then((btdevice) => {
                  navigate(`/btdevices/${btdevice.btmac}`);
                })
                .catch((e) => {
                  console.error("Problem creating device: ", e);
                  set_create_device_state({
                    ...create_device_state,
                    loading: false,
                    error: true,
                  });
                });
            }}
            disabled={create_device_state.loading}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <SearchableTable
          header={
            <TableRow>
              <TableCell>MAC Address</TableCell>
              <TableCell>IMEI</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              {/* <TableCell>Users</TableCell>*/}
              <TableCell>Company</TableCell>
              <TableCell align="right">View</TableCell>
            </TableRow>
          }
          render_row={(device) => (
            <TableRow key={device.btmac}>
              <TableCell>{device.btmac}</TableCell>
              <TableCell>{device.imei}</TableCell>
              <TableCell>{device.name}</TableCell>
              <TableCell>{device.device_type}</TableCell>
              <TableCell>{device.company_id}</TableCell>
              <TableCell align="right">
                <IconButton component={Link} to={`/btdevices/${device.btmac}`}>
                  <ArrowRight />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
          filters={{
            Name: "string",
            IMEI: "string",
            BTMAC: "string",
          }}
          onChange={(offset, limit, filters) => {
            API.bt_devices()
              .search({
                offset,
                limit,
                name: filters["Name"],
                imei: filters["IMEI"],
                btmac: filters["BTMAC"],
              })
              .then(({ content, total }) => {
                set_devices(content);
                set_total_devices(total);
              });
          }}
          rows={devices}
          total={total_devices}
        />
      </Box>
    </PageWrapper>
  );
};

export default BluetoothDeviceList;
