import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";

const Select = (props) => {
  const [input_value, set_input_value] = useState("");

  return (
    <Autocomplete
      options={props.loading ? [] : props.options}
      filterOptions={(options) => options}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      multiple={props.multiple}
      loading={props.loading}
      disabled={!props.edit_mode}
      value={props.value ?? (props.multiple ? [] : null)}
      onChange={(e, new_value) => {
        props.onChange(new_value);
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {props.loading && <CircularProgress size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
      inputValue={input_value}
      onInputChange={(e, new_value) => {
        props.onSearch(new_value);
        set_input_value(new_value);
      }}
    />
  );
};

export default Select;
