import {
  ButtonBase,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Clear, Done, Edit } from "@mui/icons-material";
import { useState, useEffect } from "react";
import EditableString from "./EditableString";

const List = (props) => {
  const [edit_mode, set_edit_mode] = useState(false);
  const [items, set_items] = useState([]);
  const [new_items, set_new_items] = useState([]);
  const [changed, set_changed] = useState([]);

  useEffect(() => {
    set_items(props.data);
    set_changed(props.data.map(() => false));
  }, [props.data]);

  return (
    <Card>
      <CardHeader
        title={props.title}
        action={
          <IconButton
            onClick={() => {
              if (edit_mode) {
                if (props.onItemsChange) {
                  let changed_items = items.filter((item, i) => changed[i]);
                  if (changed_items.length > 0)
                    props.onItemsChange(changed_items);
                }

                if (props.onItemsNew) {
                  if (new_items.length > 0) props.onItemsNew(new_items);
                  set_new_items([]);
                }
              }

              set_edit_mode(!edit_mode);
            }}
            disabled={props.loading}
          >
            {edit_mode ? <Done /> : <Edit />}
          </IconButton>
        }
      />

      <Divider />
      {props.loading && <LinearProgress />}

      <Table>
        <TableHead>
          <TableRow>
            {props.columns.map((column, column_i) => (
              <TableCell
                key={column_i}
                align={
                  column_i === props.columns.length - 1 && !edit_mode
                    ? "right"
                    : "left"
                }
              >
                <Typography variant="body1" color="textSecondary">
                  {column.name}
                </Typography>
              </TableCell>
            ))}
            {edit_mode && (
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  Remove
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, item_i) => (
            <TableRow key={item_i}>
              {props.columns.map((column, column_i) => (
                <TableCell
                  key={column_i}
                  align={
                    column_i === props.columns.length - 1 && !edit_mode
                      ? "right"
                      : "left"
                  }
                >
                  <EditableString
                    edit_mode={column.editable && edit_mode}
                    disabled={props.loading}
                    onChange={(value) => {
                      set_items([
                        ...items.slice(0, item_i),
                        {
                          ...item,
                          [column.key]: value,
                        },
                        ...items.slice(item_i + 1),
                      ]);
                      set_changed([
                        ...changed.slice(0, item_i),
                        true,
                        ...changed.slice(item_i + 1),
                      ]);
                    }}
                    value={item[column.key]}
                  />
                </TableCell>
              ))}
              {edit_mode && (
                <TableCell align="right" sx={{ py: 0 }}>
                  <IconButton
                    onClick={() => {
                      if (props.onItemRemove) props.onItemRemove(item);
                    }}
                    disabled={props.loading}
                  >
                    <Clear />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
          {new_items.map((item, item_i) => (
            <TableRow key={item_i}>
              {props.columns.map((column, column_i) => (
                <TableCell
                  key={column_i}
                  align={
                    column_i === props.columns.length - 1 && !edit_mode
                      ? "right"
                      : "left"
                  }
                >
                  <EditableString
                    edit_mode={column.editable && edit_mode}
                    disabled={props.loading}
                    onChange={(value) => {
                      set_new_items([
                        ...new_items.slice(0, item_i),
                        {
                          ...item,
                          [column.key]: value,
                        },
                        ...new_items.slice(item_i + 1),
                      ]);
                    }}
                    value={item[column.key]}
                  />
                </TableCell>
              ))}
              {edit_mode && (
                <TableCell align="right" sx={{ py: 0 }}>
                  <IconButton
                    onClick={() =>
                      set_new_items([
                        ...new_items.slice(0, item_i),
                        ...new_items.slice(item_i + 1),
                      ])
                    }
                    disabled={props.loading}
                  >
                    <Clear />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        {edit_mode && (
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={props.columns.length + 1}
                size="small"
                style={{
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                <ButtonBase
                  sx={{
                    width: "100%",
                    padding: 1.5,
                  }}
                  disabled={props.loading}
                  onClick={() => {
                    set_new_items([
                      ...new_items,
                      props.columns.reduce(
                        (obj, { key }) => ({ ...obj, [key]: "" }),
                        {}
                      ),
                    ]);
                  }}
                >
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <Add />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        pl: 1,
                      }}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Add
                      </Typography>
                    </Grid>
                  </Grid>
                </ButtonBase>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </Card>
  );
};

export default List;
