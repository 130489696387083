import EditableString from "./EditableString";
import EditableNumber from "./EditableNumber";
import EditableDate from "./EditableDate";
import Select from "./Select";

import { Checkbox } from "@mui/material";

const Editable = (props) => {
  return (
    <>
      {props.type === "string" && (
        <EditableString
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          edit_mode={props.edit_mode}
        />
      )}
      {props.type === "number" && (
        <EditableNumber
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          edit_mode={props.edit_mode}
        />
      )}
      {props.type === "date" && (
        <EditableDate
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          edit_mode={props.edit_mode}
        />
      )}
      {props.type === "boolean" && (
        <Checkbox
          checked={props.value || false}
          disabled={!props.edit_mode}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      )}
      {props.type === "select" && (
        <Select
          value={props.value}
          options={props.options}
          loading={props.loading}
          edit_mode={props.edit_mode}
          disabled={props.disabled}
          onChange={props.onChange}
          onSearch={props.onSearch}
        />
      )}
      {props.type === "multiselect" && (
        <Select
          value={props.value}
          multiple
          options={props.options}
          loading={props.loading}
          edit_mode={props.edit_mode}
          disabled={props.disabled}
          onChange={props.onChange}
          onSearch={props.onSearch}
        />
      )}
    </>
  );
};

export default Editable;
