export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const DEVICE_TYPES = [
  {
    id: 1,
    name: "Topfly OBD",
  },
  {
    id: 3,
    name: "Topfly Hard Wired",
  },
  {
    id: 4,
    name: "Defender",
  },
  {
    id: 7,
    name: "Digital Matter Bolt",
  },
  {
    id: 21,
    name: "Oyster / Yabby",
  },

  {
    id: 23,
    name: "Teltonika FMx130",
  },
  {
    id: 25,
    name: "Teltonika FMx125",
  },
  {
    id: 27,
    name: "Syrus Tracker",
  },
  {
    id: 29,
    name: "Teltonika FMx6xx",
  },
  {
    id: 77,
    name: "Moncrete",
  },
  {
    id: 30,
    name: "Teltonika FMx00x",
  },
];

export const BLUETOOTH_DEVICE_TYPES = [
  {
    id: 1,
    name: "Tyre Pressure Sensor",
  },
  {
    id: 4,
    name: "Temperature Sensor",
  },
  {
    id: 5,
    name: "Door Sensor",
  },
  {
    id: 3,
    name: "KEY FOB",
  },
  {
    id: 7,
    name: "Rotation Sensor",
  },
];
