import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import api from "../lib/api";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    }
};

const reducer = (state, action) => (handlers[action.type]
    ? handlers[action.type](state, action)
    : state);

const AuthContext = createContext({
    ...initialState,
    platform: "JWT",
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            let isAuthenticated = false;
            let user = null;
            
            try {
                const token = window.localStorage.getItem("token");

                if (token) {
                    api.token = token;

                    let exp;
                    try {
                        let claims = token.split(".")[1];
                        claims = JSON.parse(atob(claims));

                        exp = claims.exp * 1000;
                    } catch (e) {
                        // Problem retrieving token expiry
                        isAuthenticated = false;
                        user = null;
                    }

                    // Only accept if it hasn't expired
                    if (exp && exp >= Date.now()) {
                        isAuthenticated = true;
                        user = {};
                    }
                }
            } catch (err) {
                console.error(err);
                
                isAuthenticated = false;
                user = null;
            }

            dispatch({
                type: "INITIALIZE",
                payload: {
                    isAuthenticated,
                    user
                }
            });
        };

        initialize();
    }, []);

    const login = (email, password) => {
        return api.login(email, password).then(({ token }) => {
            // const user = await authApi.me(accessToken);
            // TODO: Decode JWT
            const user = {};

            localStorage.setItem("token", token);
            api.token = token;

            dispatch({
                type: "LOGIN",
                payload: {
                    user
                }
            });
        });
    };

    const logout = async () => {
        localStorage.removeItem("token");
        api.token = null;

        dispatch({ type: "LOGOUT" });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: "JWT",
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
