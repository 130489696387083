import {
  Card as MuiCard,
  CardHeader,
  Divider,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Done, Edit } from "@mui/icons-material";
import { useState, useEffect } from "react";
import Editable from "./Editable";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

const DataGridCard = (props) => {
  const [edit_mode, set_edit_mode] = useState(false);
  const [values, set_values] = useState({});

  useEffect(() => {}, []);

  return (
    <MuiCard sx={{ mb: 2 }}>
      <CardHeader title={props.title} />

      <Divider />

      <Box sx={{ height: 700, width: `100%` }}>
        <DataGridPro
          components={{
            Toolbar: GridToolbar,
          }}
          checkboxSelection={false}
          sx={{ height: 700, width: `100%` }}
          rows={props.rows}
          columns={props.columns}
        />
      </Box>
    </MuiCard>
  );
};

DataGridCard.defaultProps = { columns: [], rows: [] };

export default DataGridCard;
