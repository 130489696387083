function pad(n, size) {
    let s = n.toString();

    for (let i = s.length; i < size; i++) s = "0" + s;

    return s;
}

const f = {
    from_string: (date_string) => {
        let [ date, time ] = date_string.split("T");
        let [ year, month, day ] = date.split("-");
        let [ hours, minutes, seconds ] = time.split(":");

        let d = new Date();

        d.setUTCFullYear(year, month, day);
        d.setUTCHours(hours, minutes, seconds, 0);

        return d;
    },
    to_string: (d) => {
        let year = d.getUTCFullYear();
        let month = pad(d.getUTCMonth(), 2);
        let day = pad(d.getUTCDate(), 2);

        let hours = pad(d.getUTCHours(), 2);
        let minutes = pad(d.getUTCMinutes(), 2);
        let seconds = pad(d.getUTCSeconds(), 2);

        let date = [year, month, day].join("-");
        let time = [hours, minutes, seconds].join(":");

        return [date, time].join("T");
    },
    format: (date_string) => {
        let [ date, time ] = date_string.split("T");
        
        date = date.split("-").reverse().join("/");
        time = time.split(":");
        time[0]++;
        let meridiem = time[0] <= 12 ? "am" : "pm"

        time = time.join(":");

        return `${time}${meridiem} ${date}`;
    }
};

export default f;
