import {
  Card as MuiCard,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Done, Edit } from "@mui/icons-material";
import { useState, useEffect } from "react";
import Editable from "./Editable";

const Card = (props) => {
  const [edit_mode, set_edit_mode] = useState(false);
  const [values, set_values] = useState({});

  useEffect(() => {
    if (!edit_mode) {
      let values = {};

      for (let row of props.data) values[row.name] = row.value;

      set_values(values);
    }
  }, [props.data, edit_mode]);

  return (
    <MuiCard>
      <CardHeader
        title={props.title}
        action={
          edit_mode ? (
            <IconButton
              onClick={() => {
                set_edit_mode(false);
                props.onChange(values);
              }}
            >
              <Done />
            </IconButton>
          ) : (
            <IconButton onClick={() => set_edit_mode(true)}>
              <Edit />
            </IconButton>
          )
        }
      />

      <Divider />

      <Table>
        <TableBody>
          {props.data.map((row, i) => (
            <TableRow key={i}>
              <TableCell width="50%">
                <Typography variant="body1" color="textSecondary">
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell width="50%">
                <Editable
                  type={row.type}
                  options={row.options}
                  value={values[row.name]}
                  edit_mode={edit_mode}
                  loading={row.loading}
                  disabled={row.disabled}
                  onChange={(value) => {
                    set_values({ ...values, [row.name]: value });
                  }}
                  onSearch={row.onSearch}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </MuiCard>
  );
};

export default Card;
