import {
  Card,
  CardHeader,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";

const SearchableTable = (props) => {
  const { title, onChange, rows, total, filters } = props;

  const [pagination, set_pagination] = useState({
    page: 0,
    limit: 10,
  });
  const [loading, set_loading] = useState(false);
  const [filter_values, set_filter_values] = useState(
    Object.keys(filters).reduce((obj, filter) => {
      obj[filter] = "";
      return obj;
    }, {})
  );

  useEffect(() => set_loading(false), [rows]);

  useEffect(() => {
    set_loading(true);

    onChange(
      pagination.page * pagination.limit,
      pagination.limit,
      filter_values
    );
  }, [
    pagination.page,
    pagination.limit,
    filter_values,
    // TODO: Can't include onChange as it triggers the update each time :(
  ]);

  return (
    <Card>
      {title && <CardHeader title={title} />}
      <Grid container direction="column">
        <Grid
          item
          container
          direction="column"
          sx={{
            p: 2,
            pb: 0,
          }}
        >
          {Object.keys(filters).map((filter_name) => (
            <Grid
              item
              key={filter_name}
              sx={{
                pb: 2,
              }}
            >
              <TextField
                fullWidth
                label={filter_name}
                size="small"
                value={filter_values[filter_name]}
                onChange={(e) => {
                  let new_values = { ...filter_values };
                  new_values[filter_name] = e.target.value;

                  set_filter_values(new_values);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {loading ? (
            <LinearProgress />
          ) : (
            <Table>
              <TableHead>{props.header}</TableHead>
              <TableBody>{rows.map(props.render_row)}</TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={total}
                    page={pagination.page}
                    rowsPerPage={pagination.limit}
                    rowsPerPageOptions={[]}
                    onPageChange={(e, page) =>
                      set_pagination({ ...pagination, page })
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default SearchableTable;
