import { TextField, Typography } from "@mui/material";

const EditableString = (props) => {
  return props.edit_mode ? (
    <TextField
      value={props.value}
      variant="standard"
      onChange={(e) => props.onChange(e.target.value)}
      disabled={props.disabled || false}
    />
  ) : (
    <Typography variant="body1">{props.value}</Typography>
  );
};

export default EditableString;
