const version = "v1";

function request({ method = "GET", endpoint, query, body, json = true }) {
  if (API.token === null) throw new Error("No valid token");

  let url = new URL(`/${version}${endpoint}`, window.location.origin);
  let params = new URLSearchParams(query);
  params.set("token", API.token);
  url.search = params;

  return fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
  })
    .catch((e) => {
      console.error(`Problem making request to ${endpoint}`);
      console.error(e);
    })
    .then((res) => {
      if (json) {
        return res
          .json()
          .catch((e) => console.error(`Problem decoding JSON response: ${e}`))
          .then((body) => {
            if (res.status === 200) return body;
            else throw body;
          });
      } else return res.blob();
    });
}

const API = {
  token: null,
  companies: (param) => {
    if (typeof param === "number") {
      let company_id = param;

      return {
        // Accessor methods
        get: () =>
          request({
            endpoint: `/companies/${company_id}`,
          }).then((response) => response.content),
        update: ({ address, contact, name, api, permissions_enabled }) =>
          request({
            method: "PATCH",
            endpoint: `/companies/${company_id}`,
            body: {
              address,
              contact,
              name,
              api,
              permissions_enabled,
            },
          }).then((response) => response.content),
        delete: () =>
          request({
            method: "DELETE",
            endpoint: `/companies/${company_id}`,
          }).then((response) => response.content),

        // Other objects
        jobsites: (param) => {
          if (typeof param === "number") {
            let jobsite_id = param;

            return {
              get: () =>
                request({
                  endpoint: `/companies/${company_id}/jobsites/${jobsite_id}`,
                }).then((response) => response.content),
              update: ({ name }) =>
                request({
                  method: "PATCH",
                  endpoint: `/companies/${company_id}/jobsite/${jobsite_id}`,
                  body: {
                    name,
                  },
                }).then((response) => response.content),
              delete: () =>
                request({
                  method: "DELETE",
                  endpoint: `/companies/${company_id}/jobsites/${jobsite_id}`,
                }),
            };
          } else if (Array.isArray(param)) {
            let jobsites = param;

            return {
              get: () =>
                Promise.all(
                  jobsites.map((jobsite_id) =>
                    request({
                      endpoint: `/companies/${company_id}/jobsites/${jobsite_id}`,
                    }).then((response) => response.content)
                  )
                ),
            };
          } else if (param === undefined)
            return {
              create: ({ name }) =>
                request({
                  method: "POST",
                  endpoint: "/companies",
                  body: { name },
                }).then((response) => response.content),
              search: ({ name = "", offset, limit }) =>
                request({
                  endpoint: `/companies/${company_id}/jobsites`,
                  query: {
                    offset,
                    limit,
                    name,
                  },
                }),
            };
          else return Promise.reject("Invalid parameters");
        },

        app_settings: (param) => {
          if (param === undefined) {
            return {
              get: () =>
                request({
                  endpoint: `/companies/${company_id}/app_settings`,
                }).then((response) => {
                  console.log("Response");
                  console.log(response);
                  return response.content;
                }),
              update: ({ settings }) =>
                request({
                  method: "PATCH",
                  endpoint: `/companies/${company_id}/app_settings`,
                  body: {
                    settings,
                  },
                }).then((response) => response.content),
              create: ({ settings }) =>
                request({
                  method: "POST",
                  endpoint: "/companies",
                  body: { settings },
                }).then((response) => response.content),
            };
          } else return Promise.reject("Invalid parameters");
        },

        groups: (param) => {
          if (param === undefined)
            return {
              get: () =>
                request({
                  endpoint: `/companies/${company_id}/groups`,
                }),
            };
        },
        tags: (param) => {
          if (param === undefined)
            return {
              get: () =>
                request({
                  endpoint: `/companies/${company_id}/tags`,
                }),
            };
        },
      };
    } else if (Array.isArray(param)) {
      let company_ids = param;

      return {
        get: () =>
          Promise.all(
            company_ids.map((company_id) =>
              request({
                endpoint: `/companies/${company_id}`,
              }).then((response) => response.content)
            )
          ),
      };
    } else if (param === undefined)
      return {
        create: ({ address, contact, name, api }) =>
          request({
            method: "POST",
            endpoint: "/companies",
            body: {
              address,
              contact,
              name,
              api,
            },
          }).then((response) => response.content),
        search: ({ offset, limit, name = "" }) =>
          request({
            endpoint: "/companies",
            query: {
              offset,
              limit,
              name,
            },
          }),
      };
    else return Promise.reject("Invalid parameters");
  },
  users: (param) => {
    if (typeof param === "number") {
      let user_id = param;

      return {
        get: () =>
          request({
            endpoint: `/users/${user_id}`,
          }).then((response) => response.content),
        admin_login: () =>
          request({
            endpoint: `/users/${user_id}/admin_login`,
            method: "POST",
          }).then((response) => response.content),
        get_permissions: () =>
          request({
            endpoint: `/users/${user_id}/permissions`,
          }).then((response) => {
            return response.content;
          }),
        update_permissions: (permissions) =>
          request({
            method: "PATCH",
            endpoint: `/users/${user_id}/permissions`,
            body: {
              permissions,
            },
          }).then((response) => response.content),
        create_permissions: () =>
          request({
            method: "POST",
            endpoint: `/users/${user_id}/permissions`,
          }).then((response) => response.content),
        update: ({
          name,
          email,
          phone,
          address,
          alerts,
          external_ids,
          company,
          permission_groups,
          vmadmin,
          tsadmin,
        }) =>
          request({
            method: "PATCH",
            endpoint: `/users/${user_id}`,
            body: {
              name,
              email,
              phone,
              address,
              alerts,
              external_ids,
              company,
              permission_groups,
              vmadmin,
              tsadmin,
            },
          }).then((response) => response.content),
        delete: () =>
          request({
            method: "DELETE",
            endpoint: `/users/${user_id}`,
          }).then((response) => response.content),
      };
    } else if (Array.isArray(param)) {
      let user_ids = param;

      return {
        get: () =>
          Promise.all(
            user_ids.map((user_id) =>
              request({
                endpoint: `/users/${user_id}`,
              }).then((response) => response.content)
            )
          ),
      };
    } else if (param === undefined)
      return {
        create: ({
          name,
          password,
          email,
          phone,
          address,
          alerts,
          external_ids,
          company,
          scheduled_shifts,
          send_details_email,
        }) =>
          request({
            method: "POST",
            endpoint: "/users",
            body: {
              name,
              password,
              email,
              phone,
              address,
              alerts,
              external_ids,
              company,
              scheduled_shifts,
              send_details_email,
            },
          }).then((response) => response.content),
        search: ({
          offset,
          limit,
          name = "",
          company = "",
          permissiongroup = undefined,
        }) =>
          request({
            endpoint: "/users",
            query: {
              offset,
              limit,
              name,
              company,
              permissiongroup,
            },
          }),
      };
    else return Promise.reject("Invalid parameters");
  },

  permission_groups: (param) => {
    if (typeof param === "number") {
      let permissiongroupid = param;

      return {
        get_permissions: ({ company_id }) =>
          request({
            endpoint: `/permission_groups/${permissiongroupid}/permissions`,
            query: { company_id },
          }).then((response) => response.content),
        update: ({ approval_level }) =>
          request({
            method: "PATCH",
            endpoint: `/permission_groups/${permissiongroupid}`,
            body: {
              approval_level,
            },
          }).then((response) => response.content),
        update_permissions: ({ permissions }) =>
          request({
            method: "PATCH",
            endpoint: `/permission_groups/${permissiongroupid}/permissions`,
            body: {
              permissions,
            },
          }).then((response) => response.content),
        delete: () =>
          request({
            method: "DELETE",
            endpoint: `/permission_groups/${permissiongroupid}`,
          }),
      };
    } else if (Array.isArray(param)) {
      let permission_groups = param;

      return {
        get: () =>
          Promise.all(
            permission_groups.map((permissiongroupid) =>
              request({
                endpoint: `/permission_groups/${permissiongroupid}/permissions`,
              }).then((response) => response.content)
            )
          ),
      };
    } else if (param === undefined)
      return {
        get_all: (company_id) =>
          request({
            endpoint: `/permission_groups`,
            query: {
              company_id,
            },
          }).then((response) => response.content),
        create_permissions: ({ company_id, permissiongroupid }) =>
          request({
            method: "POST",
            endpoint: `/permission_groups/${permissiongroupid}/permissions`,
            body: {
              company_id,
            },
          }).then((response) => response.content),
        create: ({ company_id, name }) =>
          request({
            method: "POST",
            endpoint: `/permission_groups`,
            body: {
              name,
              company_id,
            },
          }).then((response) => response.content),
      };
    else return Promise.reject("Invalid parameters");
  },

  devices: (param) => {
    if (typeof param === "string") {
      let imei = param;

      return {
        get: () =>
          request({
            endpoint: `/devices/${imei}`,
          }).then((response) => response.content),
        update: ({
          name,
          mode_type,
          config,
          geofence,
          alerts,
          company,
          active,
        }) =>
          request({
            method: "PATCH",
            endpoint: `/devices/${imei}`,
            body: {
              name,
              mode_type,
              config,
              geofence,
              alerts,
              company,
              active,
            },
          }).then((response) => response.content),
        users: () => ({
          get: () =>
            request({
              endpoint: `/devices/${imei}/users`,
            }).then((response) => response.content),
          set: (users) =>
            request({
              method: "POST",
              endpoint: `/devices/${imei}/users`,
              body: { users },
            }).then((response) => response.content),
        }),
        data: () => ({
          get_all: ({ start_date, end_date, limit, offset }) =>
            request({
              endpoint: `/devices/${imei}/data`,
              query: { start_date, end_date },
            }).then((response) => response.content),
        }),
        get_info: () =>
          request({
            endpoint: `/devices/${imei}/info`,
          }).then((response) => response.content),
        create_info: ({
          brand,
          model,
          fueltype,
          fuelcapacity,
          body,
          transmission,
          doors,
          color,
          cylinders,
          gears,
          seats,
          drive,
          vin,
          registration,
          wheelsize,
          buildyear,
          fuelusage,
          toll_number,
        }) =>
          request({
            method: "POST",
            endpoint: `/devices/${imei}/info`,
            body: {
              brand,
              model,
              fueltype,
              fuelcapacity,
              body,
              transmission,
              doors,
              color,
              cylinders,
              gears,
              seats,
              drive,
              vin,
              registration,
              wheelsize,
              buildyear,
              fuelusage,
              toll_number,
            },
          }).then((response) => response.content),
        update_info: ({
          brand,
          model,
          fueltype,
          fuelcapacity,
          body,
          transmission,
          doors,
          color,
          cylinders,
          gears,
          seats,
          drive,
          vin,
          registration,
          wheelsize,
          buildyear,
          fuelusage,
          toll_number,
        }) =>
          request({
            method: "PATCH",
            endpoint: `/devices/${imei}/info`,
            body: {
              brand,
              model,
              fueltype,
              fuelcapacity,
              body,
              transmission,
              doors,
              color,
              cylinders,
              gears,
              seats,
              drive,
              vin,
              registration,
              wheelsize,
              buildyear,
              fuelusage,
              toll_number,
            },
          }).then((response) => response.content),
      };
    } else if (param === undefined)
      return {
        search: ({ offset, limit, name = "", imei = "", company = null }) =>
          request({
            endpoint: "/devices",
            query: {
              offset,
              limit,
              name,
              imei,
              company,
            },
          }),
        create: ({ name, imei, mode_type }) =>
          request({
            method: "POST",
            endpoint: `/devices`,
            body: {
              name,
              imei,
              mode_type,
            },
          }).then((response) => response.content),
      };
  },
  bt_devices: (param) => {
    if (typeof param === "string") {
      let btmac = param;

      return {
        get: () =>
          request({
            endpoint: `/bluetooth_devices/${btmac}`,
          }).then((response) => response.content),
        update: ({ name, company_id, device_type, imei }) =>
          request({
            method: "PATCH",
            endpoint: `/bluetooth_devices/${btmac}`,
            body: {
              imei,
              name,
              device_type,
              company_id,
            },
          }).then((response) => response.content),
        data: () => ({
          get_all: ({ start_date, end_date, limit, offset }) =>
            request({
              endpoint: `/bluetooth_devices/${btmac}/data`,
              query: { start_date, end_date },
            }).then((response) => response.content),
        }),
        delete: () =>
          request({
            method: "DELETE",
            endpoint: `/bluetooth_devices/${btmac}`,
          }).then((response) => response.content),
      };
    } else if (param === undefined)
      return {
        search: ({ offset, limit, name = "", imei = "", btmac = "" }) =>
          request({
            endpoint: "/bluetooth_devices",
            query: {
              offset,
              limit,
              name,
              btmac,
              imei,
            },
          }),
        create: ({ name, btmac, imei, device_type, company_id }) =>
          request({
            method: "POST",
            endpoint: `/bluetooth_devices`,
            body: {
              name,
              device_type,
              btmac,
              imei,
              company_id,
            },
          }).then((response) => response.content),
      };
  },
  login: (email, password) => {
    let url = new URL("/login", window.location.origin);

    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        username: email,
        password,
      }),
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
    })
      .catch((err) => {
        console.error(`Problem sending login request: ${err}`);
        throw new Error("Problem with login");
      })
      .then((res) => {
        if (res.status === 200)
          return res.json().catch((err) => {
            console.error(`Problem decoding JSON: ${err}`);
            throw new Error("Problem with login");
          });
        else throw new Error("Incorrect username or password");
      });
  },
};

export default API;
