import { Alert, Box, Container, LinearProgress, Snackbar } from "@mui/material";
import { Helmet } from "react-helmet-async";

const PageWrapper = (props) => {
  const { children, loading, page_title, notification } = props;

  return (
    <>
      <Helmet>
        <title>{page_title}</title>
      </Helmet>

      {loading ? (
        <LinearProgress />
      ) : (
        <Box
          sx={{
            backgroundColor: "background.default",
            height: "100%",
            py: 8,
          }}
        >
          <Container maxWidth="xl">{children}</Container>
        </Box>
      )}

      <Snackbar
        open={notification.open}
        autoHideDuration={5000}
        onClose={notification.onClose}
      >
        <Alert severity={notification.severity} onClose={notification.onClose}>
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PageWrapper;
