import { TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/lab";
import date from "../utils/date";

const EditableDate = (props) => {
  return props.edit_mode ? (
    <DateTimePicker
      renderInput={(props) => <TextField {...props} variant="standard" />}
      value={props.value ? date.from_string(props.value) : null}
      onChange={(new_value) => props.onChange(date.to_string(new_value))}
      disabled={props.disabled || false}
      inputFormat="hh:mm:ss dd/MM/yyyy"
    />
  ) : (
    <Typography variant="body1">
      {props.value && date.format(props.value)}
    </Typography>
  );
};

export default EditableDate;
